


































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import TableColumnHeader from '@/components/common/TableColumnHeader.vue'
import { filesAPI } from '@/api/files.api'
import { downloadBinaryFile } from '@/utils/files'

@Component({
  components: { TableColumnHeader },
})
export default class ReportsTable extends Vue {
  @Prop({ type: Boolean })
  loading?: boolean

  @Prop({ type: Array })
  reports!: object[]

  dateFilter = ''
  statusFilter = null

  reportsStatus = {
    finished: 'Готов',
    new: 'Новый',
    in_progress: 'В процессе',
  }
  statusOptions = [
    { label: 'Готов', value: 'finished' },
    { label: 'Новый', value: 'new' },
    { label: 'В процессе', value: 'in_progress' },
  ]

  get userRole() {
    return this.$store.getters['user/user'].role
  }

  get isShowVendorColumn() {
    return !['user', 'shopAdmin', 'vendorUser'].includes(this.userRole)
  }

  handleChangeSort(data: object): void {
    this.$emit('change-sort', data)
  }

  handleAddFilter(filter: { data: string; target: string }): void {
    this.$emit('add-filter', { data: filter.data, target: filter.target })
  }

  handleShowUserInfo(id: string): void {
    this.$emit('show-user-info', id)
  }

  handleShowVendorInfo(id: string): void {
    this.$emit('show-vendor-info', id)
  }

  async handleDownloadReport(id: string): Promise<void> {
    const [_, response] = await filesAPI.download(id)

    if (response) {
      downloadBinaryFile(response.data, response.headers)
    }
  }
}
