var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"users-table not-clickable",attrs:{"data":_vm.users},on:{"sort-change":_vm.handleChangeSort}},[_c('el-table-column',{attrs:{"sortable":"custom","prop":"DATE","label":"Дата","min-width":"210","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"date","columnName":">=DATE","label":"Дата","inputLabel":"Дата"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"USER_LOGIN","label":"Участник","min-width":"160","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"USER_LOGIN","label":"Участник","inputLabel":"Участник"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"link",on:{"click":function($event){$event.preventDefault();return _vm.handleShowUserInfo(row.UF_USER_ID)}}},[_vm._v(" "+_vm._s(row.USER_LOGIN)+" ")])]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"AMOUNT","label":"Сумма","min-width":"130","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"AMOUNT","label":"Сумма","inputLabel":"Сумма"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"DEBIT","label":"Тип операции","min-width":"130","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"select","columnName":"DEBIT","label":"Тип операции","inputLabel":"Тип операции","options":_vm.typeOptions},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.DEBIT === 'Y' ? '+' : '-'))])]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"ENTITY_TYPE","label":"Тип транзакции","min-width":"160","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"select","columnName":"ENTITY_TYPE","label":"Тип транзакции","inputLabel":"Тип транзакции","options":_vm.transactionOptions},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm.transactionsTypes[row.ENTITY_TYPE]))])]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"ORDER_EXT_ID","label":"Заказ","min-width":"120","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"ORDER_EXT_ID","label":"Заказ","inputLabel":"Заказ"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"link",on:{"click":function($event){$event.preventDefault();return _vm.handleShowOrderInfo(row.ORDER_ID)}}},[_vm._v(" "+_vm._s(row.ORDER_EXT_ID)+" ")])]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"ORDER_PRICE","label":"Стоимость заказа","min-width":"210","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"ORDER_PRICE","label":"Стоимость заказа","inputLabel":"Стоимость заказа"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"ACCRUAL_EXT_ID","label":"Начисление","min-width":"160","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"ACCRUAL_EXT_ID","label":"Начисление","inputLabel":"Начисление"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"link",on:{"click":function($event){$event.preventDefault();return _vm.handleShowAccrualInfo(row.ACCRUAL_ID)}}},[_vm._v(" "+_vm._s(row.ACCRUAL_EXT_ID)+" ")])]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"ACCRUAL_PRICE","label":"Сумма начисления","min-width":"200","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"ACCRUAL_PRICE","label":"Сумма начисления","inputLabel":"Сумма начисления"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"CODE_CODE","label":"Код","min-width":"160","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"CODE_CODE","label":"Код","inputLabel":"Код"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.CODE_CODE)?_c('div',{staticClass:"link",on:{"click":function($event){$event.preventDefault();return _vm.handleShowCodeInfo(row.CODE_ID)}}},[_vm._v(" "+_vm._s(row.CODE_CODE)+" ")]):_vm._e()]}}])}),(_vm.isShowVendorColumn)?_c('el-table-column',{attrs:{"sortable":"custom","prop":"VENDOR_NAME","label":"Вендор","min-width":"200","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"VENDOR_NAME","label":"Вендор","inputLabel":"Вендор"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"link",on:{"click":function($event){$event.preventDefault();return _vm.handleShowVendorInfo(row.VENDOR_ID)}}},[_vm._v(" "+_vm._s(row.VENDOR_NAME)+" ")])]}}],null,false,3871106792)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }