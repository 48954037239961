

























































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { VendorsListItemType } from '@/types/transactions'
import TableColumnHeader from '@/components/common/TableColumnHeader.vue'

@Component({
  components: { TableColumnHeader },
})
export default class VendorsTable extends Vue {
  @Prop({ type: Boolean })
  loading?: boolean

  @Prop({ type: Array })
  vendors!: VendorsListItemType[]

  dateFilter = ''
  typeFilter = null

  typeOptions = [
    {
      value: 'Y',
      label: '+',
    },
    {
      value: 'N',
      label: '-',
    },
  ]

  get userRole() {
    return this.$store.getters['user/user'].role
  }

  get isShowVendorColumn() {
    return !['user', 'shopAdmin', 'vendorUser'].includes(this.userRole)
  }

  handleChangeSort(data: object): void {
    this.$emit('change-sort', data)
  }

  handleAddFilter(filter: { data: string; target: string }): void {
    this.$emit('add-filter', { data: filter.data, target: filter.target })
  }

  handleShowUserInfo(id: string): void {
    this.$emit('show-user-info', id)
  }

  handleShowCodeInfo(id: string): void {
    this.$emit('show-code-info', id)
  }

  handleShowVendorInfo(id: string): void {
    this.$emit('show-vendor-info', id)
  }
}
