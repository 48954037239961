














































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { UsersListItemType } from '@/types/transactions'
import TableColumnHeader from '@/components/common/TableColumnHeader.vue'

@Component({ components: { TableColumnHeader } })
export default class UsersTable extends Vue {
  @Prop({ type: Boolean })
  loading?: boolean

  @Prop({ type: Array })
  users!: UsersListItemType[]

  dateFilter = ''
  typeFilter = null
  transactionFilter = null

  typeOptions = [
    {
      value: 'Y',
      label: '+',
    },
    {
      value: 'N',
      label: '-',
    },
  ]
  transactionOptions = [
    { label: 'Заказ', value: 'ORDER' },
    { label: 'Начисление', value: 'ACCRUAL' },
    { label: 'Код', value: 'CODE' },
  ]

  transactionsTypes = {
    ORDER: 'Заказ',
    ACCRUAL: 'Начисление',
    CODE: 'Код',
  }

  get userRole() {
    return this.$store.getters['user/user'].role
  }

  get isShowVendorColumn() {
    return !['user', 'shopAdmin', 'vendorUser'].includes(this.userRole)
  }

  handleChangeSort(data: object): void {
    this.$emit('change-sort', data)
  }

  handleAddFilter(filter: { data: string; target: string }): void {
    this.$emit('add-filter', { data: filter.data, target: filter.target })
  }

  handleShowUserInfo(id: string): void {
    this.$emit('show-user-info', id)
  }

  handleShowCodeInfo(id: string): void {
    this.$emit('show-code-info', id)
  }

  handleShowVendorInfo(id: string): void {
    this.$emit('show-vendor-info', id)
  }

  handleShowOrderInfo(id: string): void {
    this.$emit('show-order-info', id)
  }

  handleShowAccrualInfo(id: string): void {
    this.$emit('show-accrual-info', id)
  }
}
